import { createSlice } from "@reduxjs/toolkit";

const wonGameSlice = createSlice({
  name: "wonGame",
  initialState: false,
  reducers: {
    setWonGame(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setWonGame } = wonGameSlice.actions;
export default wonGameSlice.reducer;
