import { customizationParser } from "./services/helpers/customization-helper";

// Base assets with branding-specific paths
const defaultAssets = {
  policeLine: require("./img/default/rr_police_line.png"),
  coinImage: require("./img/default/rr_coin_glow.png"),
  jackpotCoin: require("./img/default/rr_jackpot_coin.png"),
  musicLoop: require("./sounds/default/rr-background-music-theme.mp3"),
  fire: require("./img/default/rr_fire.png"),
  raccoonRun: Array.from({ length: 18 }, (_, i) => {
    const imageIndex = i <= 9 ? `0${i}` : i;
    return require(`./img/default/Football_player_run_side/Football_player_run_side_0000_000${imageIndex}.png`)
  }),
  playerHurt: Array.from({ length: 30 }, (_, i) => {
    const imageIndex = i <= 9 ? `0${i}` : i;
    return require(`./img/default/Football_player_hurt/Football_player_hurt_0000_000${imageIndex}.png`)
  }),
  playerRunBlackHair: Array.from({ length: 40 }, (_, i) => {
    const imageIndex = i <= 9 ? `0${i}` : i;
    return require(`./img/default/Football_player_run_black_hair/Football_player_run_black_hair_000${imageIndex}.png`)
  }),
  playerRunWhiteHair: Array.from({ length: 40 }, (_, i) => {
    const imageIndex = i <= 9 ? `0${i}` : i;
    return require(`./img/default/Football_player_run_light_hair/Football_player_run_light_hair_000${imageIndex}.png`)
  }),
  playerTackleImages: Array.from({ length: 18 }, (_, i) => {
    const imageIndex = i <= 9 ? `0${i}` : i;
    return require(`./img/default/Football_player_slider/Football_player_slider_000${imageIndex}.png`)
  }),
  raccoonIdle: Array.from({ length: 40 }, (_, i) => {
    const imageIndex = i <= 9 ? `0${i}` : i;
    return require(`./img/default/Football_player_idle/Football_player_idle_0000_000${imageIndex}.png`);
  }),
  playerWon: Array.from({ length: 35 }, (_, i) => {
    const imageIndex = i <= 9 ? `0${i}` : i;
    return require(`./img/default/Football_player_jump/Football_player_jump_0000_000${imageIndex}.png`);
  }),
  endGameModal1: require("./img/default/racoon-comic-1.png")
};

// You may need to dynamically load branding assets here or at initialization
const brandingAssets = {
  winner: {
    policeLine: require("./img/winner/rr_police_line.png"),
    coinImage: require("./img/winner/rr_coin_glow.png"),
    musicLoop: require("./sounds/winner/rr-background-music-theme.mp3"),
    endGameModal1: require("./img/winner/racoon-comic-1.png")
  },
  // Add other branding-specific assets here
};

// Function to get branding assets based on the current branding
const getCurrentAssets = (branding = "default") => {
  return {
    ...defaultAssets,
    ...(brandingAssets[branding] || {}),
  };
};

const customization = customizationParser();

// Set the current branding somewhere in your app initialization
const currentBranding = customization?.branding || ""; // replace with actual branding logic

// Export assets based on the current branding
export const assets = getCurrentAssets(currentBranding);
