import login from './entities/login-slice';
import health from './entities/health-slice';
import takeStep from './entities/take-step-slice';
import bet from './entities/bet-slice';
import cashout from './entities/cashout-slice';
import updatePlayerAvatar from './entities/update-player-avatar-slice';
import playerSettings from './entities/player-settings-slice';
import gameDifficulty from './entities/game-difficulty-slice';
import betAmount from './entities/game-controls/bet-amount-slice';
import raccoonMove from './entities/raccoon-move-slice';
import raccoonTakeOver from './entities/raccoon-take-over';
import popupMessage from './entities/popup-message-slice';
import spinner from './entities/spinner-slice';
import gameEnded from './entities/game-ended-slice';
import gameCashedOut from './entities/game-cashed-out-slice';
import gameStarted from './entities/game-started-slice';
import raccoonWalking from './entities/raccoon-walking-slice';
import hotKeys from './entities/game-controls/hot-keys-slice';
import soundsLoaded from './entities/sounds-loaded-slice';
import ticketTimer from './entities/ticket-timer-slice';
import jackpot from './entities/jackpot-slice';
import jackpotModal from './entities/modals/jackpot-modal-slice';
import balance from './entities/balance-slice';
import cancel from './entities/cancel-slice';
import musicVolume from './entities/game-controls/music-volume-slice';
import winningStrike from './entities/winning-strike-slice';
import autoPlay from './entities/auto-play-slice';
import numberOfBets from './entities/number-of-bets-slice';
import autoPlayTabActive from './entities/auto-play-tab-active-slice';
import autoPlayTargetLane from './entities/auto-play-target-lane-slice';
import { combineReducers } from 'redux';
import endGameModal from './entities/modals/end-game-modal-slice';
import maxWinModal from './entities/modals/max-win-modal-slice';
import autoBet from './entities/auto-bet-slice';
import escapedModal from './entities/modals/escaped-modal-slice';
import remainingNumberOfBets from './entities/remaining-no-of-bets-slice';
import escapedAndEndGameModalsActive from './entities/modals/escaped-and-end-game-modals-active-slice';
import jackpotAndMaxWinModalsActive from './entities/modals/jackpot-and-max-win-modals-active-slice';
import maxWinAndEndGameModalsActive from './entities/modals/max-win-and-end-game-modals-active-slice';
import bounsRoundsModal from './entities/modals/bonus-rounds-modal-slice';
import bonusLabel from './entities/bonus-label-slice';
import freeBet from './entities/free-bet-slice';
import freeTicketInfo from './entities/freetickets/free-ticket-info-slice';
import freeBetsState from './entities/free-bets-state-slice';
import webSocketStatus from './entities/web-sock-status-slice';
import cumulativeBonusLabel from './entities/cumulative-bonus-label-slice';
import bonusCumulative from './entities/freetickets/bonus-cumulative-slice';
import cumulativeWonModal from './entities/modals/cumulative-won-modal-slice';
import activeTab from './entities/focused-tab-slice';
import betHistory from './entities/bet-history-slice';
import jackpotHistory from './entities/jackpot-history-slice';
import wonGame from './entities/won-game-slice';
import tempLoweredMusicVolume from './entities/game-controls/temp-lowered-music-volume-slice';

export default combineReducers({
  login,
  health,
  bet,
  takeStep,
  cashout,
  updatePlayerAvatar,
  playerSettings,
  gameDifficulty,
  raccoonMove,
  betAmount,
  raccoonTakeOver,
  popupMessage,
  spinner,
  gameEnded,
  gameCashedOut,
  gameStarted,
  raccoonWalking,
  hotKeys,
  soundsLoaded,
  ticketTimer,
  wonGame,
  jackpot,
  balance,
  jackpotModal,
  cancel,
  endGameModal,
  musicVolume,
  winningStrike,
  autoPlay,
  numberOfBets,
  autoPlayTabActive,
  autoPlayTargetLane,
  maxWinModal,
  escapedModal,
  escapedAndEndGameModalsActive,
  jackpotAndMaxWinModalsActive,
  maxWinAndEndGameModalsActive,
  webSocketStatus,
  remainingNumberOfBets,
  autoBet,
  bounsRoundsModal,
  bonusLabel,
  freeBet,
  freeBetsState,
  freeTicketInfo,
  cumulativeBonusLabel,
  bonusCumulative,
  cumulativeWonModal,
  activeTab,
  betHistory,
  jackpotHistory,
  tempLoweredMusicVolume
});
