import { createSlice } from "@reduxjs/toolkit";

const tempLoweredMusicVolumeSlice = createSlice({
  name: "tempLoweredMusicVolume",
  initialState: 0.0425,
  reducers: {
    setTempLoweredMusicVolume(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setTempLoweredMusicVolume } = tempLoweredMusicVolumeSlice.actions;
export default tempLoweredMusicVolumeSlice.reducer;
