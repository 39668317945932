import { assets } from "../../assets";

/**
 * Singleton instance
 */
class PlayerImagesLoader {
  constructor() {
    if (PlayerImagesLoader.instance == null) {
      // params
      this.whiteHairImages = [];
      this.blackHairImages = [];
      this.tackleImages = [];
      PlayerImagesLoader.instance = this;
    }

    return PlayerImagesLoader.instance;
  }

  loadTackleImages() {
    if (assets?.playerTackleImages?.length) {
      const images = assets.playerTackleImages.map((src) => {
        const img = new Image();
        img.src = src;
        return img;
      });
  
      this.tackleImages = images;
    }
  }

  loadPlayerImages = () => {
    if (assets?.playerRunBlackHair?.length) {
      const images = assets.playerRunBlackHair.map((src) => {
        const img = new Image();
        img.src = src;
        return img;
      });
  
      this.blackHairImages = images;
    }

    if (assets?.playerRunWhiteHair?.length) {
      const images = assets.playerRunWhiteHair.map((src) => {
        const img = new Image();
        img.src = src;
        return img;
      });
  
      this.whiteHairImages = images;
    }
  }

  getRandomImageSet() {
    const isBlack = Math.random() < 0.5;

    // Return the corresponding image array
    return isBlack ? this.blackHairImages : this.whiteHairImages;
  }

  getTackleImageSet() {
    return this.tackleImages;
  }
}

const playerImagesLoader = new PlayerImagesLoader();
export default playerImagesLoader;
